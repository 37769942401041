import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`OHS 4×6\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15 Full Snatch (95/65)`}</p>
    <p>{`15 Burpees Over Bar`}</p>
    <p>{`20 OHS (95/65)`}</p>
    <p>{`20 Burpees Over Bar`}</p>
    <p>{`25 Power Snatch (95/65)`}</p>
    <p>{`25 Burpees Over Bar`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts October 10th!  Register at:
Games.CrossFit.com.  Each week the Open wod will be Friday’s class wod
so plan on attending on Friday in order to be judged.  We’ll have our
evening classes as our “Friday Night Throwdown” from 4:00-7:00 rather
than our traditional class structure.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      